
import { defineComponent } from "vue";
import { mapActions } from "vuex";

export default defineComponent({
  name: "CustomerFieldServices",
  created() {
    this.getFieldServiceControl();
  },
  methods: {
    ...mapActions({
      getFieldServiceControl: "fieldServiceControl/getFieldServiceControl",
    }),
  },
});
